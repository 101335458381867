<template>
  <v-container fluid>
    <status :e="e" :id="id" :r="r" :df="df" end />

    <wkbericht :id="id" :df="df" :e="e" />
  </v-container>
</template>

<script>
export default {
  name: 'finish',

  components: {
    Status: () => import('./general/status'),
    Wkbericht: () => import('./finish/wkbericht')
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    tab: 'status'
  })
}
</script>

<style scoped>

</style>
